<template>
	<div class="home-body">
		<div class="body-item">
			<Card :title="'设备总数 '+data.device_statistic.total_number" style="margin-right: 16px;">
				<Row style="min-width: 250px;">
					<Col :span="12" style="padding-bottom: 8px;">
					<div class="statistic-title">绑定设备</div>
					<div class="statistic-content">{{ data.device_statistic.bound_number }}</div>
					</Col>
					<Col :span="12" style="padding-bottom: 8px;">
					<div class="statistic-title">在线设备</div>
					<div class="statistic-content">{{ data.device_statistic.online_number }}</div>
					</Col>
					<Col :span="12" style="padding-bottom: 8px;">
					<div class="statistic-title">未绑定设备</div>
					<div class="statistic-content">{{ data.device_statistic.no_bound_number }}</div>
					</Col>
					<Col :span="12" style="padding-bottom: 8px;">
					<div class="statistic-title">离线设备</div>
					<div class="statistic-content">{{ data.device_statistic.offline_number }}</div>
					</Col>
				</Row>
			</Card>
			<Card title="报警">
				<Row style="min-width: 250px;">
					<Col :span="12" style="padding-bottom: 8px;">
					<div class="statistic-title">今日报警</div>
					<div class="statistic-content">{{ data.alarm_statistic.today_alarm }}</div>
					</Col>
					<Col :span="12" style="padding-bottom: 8px;">
					<div class="statistic-title">已处理</div>
					<div class="statistic-content">{{ data.alarm_statistic.processed_alarm }}</div>
					</Col>
				</Row>
			</Card>
		</div>
		<div class="body-item">
			<Card title="设备类型占比" style="margin-right: 16px;width: 33.33%;min-width: 320px;">
				<div id="echart1" style="height: 300px;"></div>
			</Card>
			<Card title="今日报警类型占比" style="width: 33.33%;min-width: 320px;">
				<div id="echart2" style="height: 300px;"></div>
			</Card>
		</div>
	</div>
</template>

<script>
	var _this, echart1, echart2;
	import * as echarts from 'echarts';
	export default {
		name: 'IotHomeIndex',
		data() {
			return {
				data: {
					device_statistic: {
						total_number: 0, // 总计
						bound_number: 0, // 绑定的
						online_number: 0, // 在线的
						no_bound_number: 0, // 未绑定的
						offline_number: 0 // 离线的
					},
					device_data: [],
					alarm_statistic: {
						today_alarm: 0,
						processed_alarm: 0
					},
					alarm_data: []
				},
				option1: {
					tooltip: {
						trigger: 'item'
					},
					legend: {
						bottom: '5%',
						left: 'center'
					},
					series: [{
						name: '设备类型占比',
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 10,
							borderColor: '#fff',
							borderWidth: 2
						},
						labelLine: {
							show: true
						},
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						},
						data: [{
								value: 4,
								name: '手表'
							},
							{
								value: 1,
								name: '网关'
							},
						]
					}]
				},
				option2: {
					tooltip: {
						trigger: 'item'
					},
					series: [{
						name: '报警类型占比',
						type: 'pie',
						radius: '70%',
						data: [{
							value: 4,
							name: '低电警告'
						}, ],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}]
				}
			}
		},
		created() {
			_this = this;
			setTimeout(() => {
				this.init();
			}, 200)
		},
		mounted() {
			//图表自适应大小
			window.addEventListener('resize', () => {
				echart1.resize();
				echart2.resize();
			});
		},
		methods: {
			init() {
				this.requestApi('/adm/get_iot_home_data').then((res)=>{
					if(res.status == 1){
						_this.data = res.data;
						_this.option1.series[0].data = res.data.device_data;
						_this.option2.series[0].data = res.data.alarm_data;
						// 设备类型占比
						var echart_dom1 = document.getElementById('echart1');
						echart1 = echarts.init(echart_dom1);
						echart1.setOption(_this.option1);
						// 报警类型占比
						var echart_dom2 = document.getElementById('echart2');
						echart2 = echarts.init(echart_dom2);
						echart2.setOption(_this.option2);
					}
				});
				
			},
		}
	}
</script>

<style scoped>
	.home-body {
		background-color: #fff;
		padding: 1rem;
	}

	.body-item {
		display: flex;
		margin-bottom: 24px;
	}

	.statistic-title {
		margin-bottom: 4px;
		color: #00000073;
		font-size: 14px;
	}

	.statistic-content {
		color: #000000d9;
		font-size: 24px;
	}
</style>
